import {  Box, Container, Heading, Text, VStack } from "@chakra-ui/react"
import { useInView } from 'react-intersection-observer'
import { useRef } from "react"

interface Member {
  subtitle: String,
  name: String
}

interface CreditTitleArgs {
  title: String,
  members: Member[]
}

const CreditTitle = (props: { args: CreditTitleArgs }) => {
  const textsRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    {
      triggerOnce: true,
    }
  )
  if(inView) {
    textsRef.current?.classList.remove("initial-hidden")
    textsRef.current?.classList.add("fast-blur-animation")
  }
  return(
    <Box textAlign="center" m="4rem" ref={ref}>
      <VStack spacing="3rem" ref={textsRef} className="initial-hidden">
        <Text fontSize="sm">{ props.args.title }</Text>
        {
          props.args.members.map(member => {
            return (
              <VStack key={member.name as React.Key}>
                <Text fontSize="xs" color="blue">{member.subtitle}</Text>
                <Text fontSize="sm">{member.name}</Text>
              </VStack>
            )
          })
        }
      </VStack>
    </Box>
  )
}

export const CastAndStaff = () => {
  const  sectionTitleRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
    }
  )
  if(inView) {
    sectionTitleRef.current?.classList.remove("initial-hidden")
    sectionTitleRef.current?.classList.add("slide-from-left")
  }
  return(
    <Box id="cast-and-staff">
      <Container maxW={["sm", "xl"]} ref={ref}>
        <Heading as="h1" display="inline-block" bgColor="yellow" padding="1rem 3rem" fontWeight="medium" ref={sectionTitleRef} className="initial-hidden">
          Cast & Staff
        </Heading>
        <CreditTitle args={{ 
          title: 'CAST',
          members: [
            {
              subtitle: '横浜成海',
              name: '仲村宗悟'
            },
            {
              subtitle: '白鳥乃以',
              name: '立花慎之介'
            },
            {
              subtitle: '櫻田聖二郎',
              name: '野津山幸宏'
            }
          ]
        }}/>
        <CreditTitle args={{ 
          title: 'STAFF',
          members: [
            {
              subtitle: '脚本',
              name: '綾奈ゆにこ'
            },
            {
              subtitle: '脚本',
              name: '結来月ひろは'
            },
            {
              subtitle: 'キャラクターデザイン',
              name: '種村みなみ'
            }
          ]
        }}/>
      </Container>
    </Box>
  )
}