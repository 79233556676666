import {  Box, Center, Container, Heading, HStack, Image, Text, VStack } from "@chakra-ui/react"
import { useRef } from "react"
import { useInView } from 'react-intersection-observer'
import KeyVisual from "./assets/key-visual.png"
import AmazonMusic from "./assets/podcast/listen-on-amazon-music.png"
import ApplePodcasts from "./assets/podcast/listen-on-apple-podcasts.png"
import GooglePodcasts from "./assets/podcast/listen-on-google-podcasts.png"
import Spotify from "./assets/podcast/listen-on-spotify.png"

export const OnAir = () => {
  const sectionTitleRef = useRef<HTMLDivElement>(null)
  const keyVisualRef = useRef<HTMLDivElement>(null)
  const podcastBtnRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
    }
  )
  if(inView) {
    sectionTitleRef.current?.classList.add("slide-from-left")
    keyVisualRef.current?.classList.add("fade-in-slide")
    podcastBtnRef.current?.classList.add("fade-in-slide")
    sectionTitleRef.current?.classList.remove("initial-hidden")
    keyVisualRef.current?.classList.remove("initial-hidden")
    podcastBtnRef.current?.classList.remove("initial-hidden")
  }
  return(
    <Box mb="10rem" id="on-air" >
      <Container maxW={["sm", "xl"]} mb="5rem" ref={ref}>
        <Heading as="h1" display="inline-block" bgColor="yellow" padding="1rem 3rem" fontWeight="medium" ref={sectionTitleRef} className="initial-hidden">
          On Air
        </Heading>
        <Container>
          <VStack spacing="2rem">
            <Center ref={keyVisualRef} className="initial-hidden">
              <Image src={KeyVisual} />
            </Center>
            <Center>
              <Text fontSize="xs">全4話 2023年初夏 Podcastアプリにて無料配信予定</Text>
            </Center>
            <VStack spacing={3} ref={podcastBtnRef} className="initial-hidden">
              <HStack>
                <VStack>
                  <Image src={ApplePodcasts} borderRadius="10px"/>
                </VStack>
                <VStack>
                  <Image src={AmazonMusic} borderRadius="10px" />
                </VStack>
              </HStack>
              <HStack>
                <VStack>
                  <Image src={Spotify} borderRadius="10px" />
                </VStack>
                <VStack>
                  <Image src={GooglePodcasts} borderRadius="10px" />
                </VStack>
              </HStack>
            </VStack>
          </VStack>
        </Container>
      </Container>
    </Box>
  )
}