import * as React from "react"
import {
  Drawer,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  IconButton,
  Text,
  Image,
  Box,
  Link,
} from "@chakra-ui/react"
import { Link as Scroll } from 'react-scroll';
import { HamburgerIcon } from "@chakra-ui/icons"
import TwitterImage from "./assets/twitter.svg"

export const MenuDrawer = () => {
  const btnRef = React.useRef<HTMLButtonElement>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const scrollRef = React.useRef<HTMLElement>(null)
  const onClickMenuItem = (idToScroll: string) => {
    onClose()
    document.getElementById(idToScroll)?.scrollIntoView()

  }
  return (
  <>
    <Drawer
      isOpen={isOpen}
      placement="left"
      onClose={onClose}
      finalFocusRef={btnRef}
    >
      <DrawerOverlay />
      <DrawerContent bgColor="yellow" fontFamily="montserrat">
        <DrawerCloseButton />
        <DrawerHeader></DrawerHeader>
        <DrawerBody onClick={() => onClickMenuItem('introduction')}>
          <Text borderBottom="1px" pb="0.8rem">Introduction</Text>
          
        </DrawerBody>
        <DrawerBody onClick={() => onClickMenuItem('character')}>
          <Text borderBottom="1px" pb="0.8rem">Character</Text>
        </DrawerBody>
        <DrawerBody onClick={() => onClickMenuItem('on-air')}>
          <Text borderBottom="1px" pb="0.8rem">On Air</Text>
        </DrawerBody>
        <DrawerBody onClick={() => onClickMenuItem('crowdfunding')}>
          <Text borderBottom="1px" pb="0.8rem">Crowdfunding</Text>
        </DrawerBody>
        <DrawerBody onClick={() => onClickMenuItem('cast-and-staff')}>
          <Text borderBottom="1px" pb="0.8rem">Cast & Staff</Text>
        </DrawerBody>
        <DrawerBody>
          <Link href="https://twitter.com/mikacyaofficial" isExternal>
            <Image src={TwitterImage} />
          </Link>
        </DrawerBody>
        <DrawerBody>
        </DrawerBody>
        <DrawerBody>
        </DrawerBody>
        <DrawerBody>
        </DrawerBody>
        <DrawerBody>
        </DrawerBody>
        <DrawerBody>
        </DrawerBody>
        <DrawerBody>
        </DrawerBody>
        <DrawerBody>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
    <IconButton
        aria-label="HamburgerIcon"
        w="60px"
        h="60px"
        icon={<HamburgerIcon />} 
        position="fixed"
        top="0"
        left="0"
        bgColor="white"
        borderRadius="0"
        zIndex="sticky"
        ref={btnRef}
        onClick={onOpen}
        />
  </>
  )
}
