import { Center, Text } from "@chakra-ui/react"
import parse from "html-react-parser"

export default function CircleNote(props: { 
  content: string
  isLeftSide: Boolean
 }) {
  return (
    <Center borderRadius='50%'
    bgColor='yellow'
    width='250px'
    height='250px'
    margin='0 auto'
    position='absolute'
    top='410px'
    left={ props.isLeftSide ? '-40px' : 'auto' }
    right={ props.isLeftSide ? 'auto' : '-40px' }
    >
      <Text fontSize="2xs" fontWeight="bold">{ parse(props.content) }</Text>
    </Center>)
}