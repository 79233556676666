import { Box, Button, Center, Container, Flex, Heading, HStack, IconButton, Image, Img, Spacer, Text, VStack } from "@chakra-ui/react"
import '@fontsource/montserrat'
import '@fontsource/sawarabi-gothic'
import 'use-sound'
import { useInView } from 'react-intersection-observer'
import Ellipse from './assets/ellipse.png'
import YokohamaImage from "./assets/character/横浜成海.png"
import ShiratoriImage from "./assets/character/白鳥乃以.png"
import SakuradaImage from "./assets/character/櫻田聖二郎.png"
import YokohamaVoice1 from "./assets/voice/横浜成海_1.wav"
import ShiratoriVoice1 from "./assets/voice/白鳥乃以_1.wav"
import SakuradaVoice1 from "./assets/voice/櫻田聖二郎_1.wav"
import YokohamaVoice2 from "./assets/voice/横浜成海_2.wav"
import ShiratoriVoice2 from "./assets/voice/白鳥乃以_2.wav"
import SakuradaVoice2 from "./assets/voice/櫻田聖二郎_2.wav"


import { Character } from "./Character"
import { useRef } from "react"

export const Characters = () => {
  const  sectionTitleRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
      threshold: 1,
    }
  )
  if(inView) {
    sectionTitleRef.current?.classList.remove("initial-hidden")
    sectionTitleRef.current?.classList.add("slide-from-left")
  }
  return(
    <Box mb="10rem" id="character">
      <Container maxW={["sm", "xl"]} ref={ref}>
        <Heading as="h1" display="inline-block" bgColor="yellow" padding="1rem 3rem" fontWeight="medium" ref={sectionTitleRef} className="initial-hidden">
          <Center>
          Character
          </Center>
        </Heading>
      </Container>
      <Container bgImage={Ellipse} bgSize="cover">
        <Character
          lastName='横浜'
          firstName="成海"
          nameAlpha="Narumi Yokohama"
          cv='CV：仲村宗悟'
          copy='ノイと櫻田は高校の同級生なんだ。'
          description='6月12日生まれ 182㎝<br/>
          モデルとインフルエンサーの2つの<br/>顔を持つ。<br/>
          写真部では幽霊部員だったが、白鳥<br/>とは当時から仲が良い。<br/>
          持ち前の明るさで、周りの盛り上げ<br/>役になることが多い。'
          image={YokohamaImage}
          characterImageX={10}
          voiceUrls={[YokohamaVoice1, YokohamaVoice2]}
          characterAlign='left' />
      </Container>
      <Container bgImage={Ellipse} bgSize="cover">
        <Character
          lastName='白鳥'
          firstName="乃以"
          nameAlpha="Noi Shiratori"
          cv='CV: 立花慎之介'
          copy='久しぶりに会いたいなって思って…<br/>なんて言ったら、どんな顔するかな'
          description='9月14日生まれ 177㎝<br/>
          製薬会社に勤務する営業部若手のエー<br/>ス。
          高校時代は生徒会長を務め、横浜と櫻<br/>田どちらとも仲が良い。<br/>
          社交的だが、本心が読みづらい。'
          image={ShiratoriImage}
          characterImageX={45}
          voiceUrls={[ShiratoriVoice1, ShiratoriVoice2]}
          characterAlign='right' />
        </Container>
        <Container bgImage={Ellipse} bgSize="cover">
          <Character
            lastName='櫻田'
            firstName="聖二郎"
            nameAlpha="Seijiro Sakurada"
            cv='CV: 野津山幸宏'
            copy='建築巡りとかあいつ、興味なさそう…'
            description='10月10日生まれ 174㎝<br/>
            設計事務所で一人前の建築家を目指し<br/>日々修行中。<br/>
            人と話すのは少し苦手だが、好きなも<br/>のについては饒舌になる。<br/>
            自分とは真逆の性格をしている横浜と<br/>は高校時代からあまり話したことがな<br/>い。'
            image={SakuradaImage}
            characterImageX={93}
            voiceUrls={[SakuradaVoice1, SakuradaVoice2]}
            characterAlign='left' />
        </Container>
      
    </Box>
  )
}