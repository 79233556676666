import {  Box, Center, Container, Heading, HStack, Image, Spacer, Text, VStack } from "@chakra-ui/react"

import { useInView } from 'react-intersection-observer'
import { useRef } from "react"
import BsNittere from './assets/sponsor/BS日テレ.gif'
import GreenFunding from './assets/sponsor/GREEN_FUNDING.png'
import Jave from './assets/sponsor/JAVE.png'
import Spinear from './assets/sponsor/SPINEAR.png'

export const Sponsor = () => {
  const  slideRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
    }
  )
  if(inView) {
    slideRef.current?.classList.add("fade-in-slide")
  }
  return(
    <Container maxW="xs" mb="5rem" ref={ref}>
      <VStack spacing="3rem"  ref={slideRef}>
          <HStack spacing="5rem" justifyContent="center">
            <Image src={Spinear} w="25%" />
            <Image src={Jave} w="25%"/>
          </HStack>
        <Image src={BsNittere} w="50%"/>
        <Image src={GreenFunding} w="50%"/>
      </VStack>
    </Container>

  )
}