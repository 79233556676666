import { extendTheme } from '@chakra-ui/react'

export const theme = extendTheme({
  fonts: {
    body: `"游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif`,
  },
  colors: {
    yellow: '#FFD704',
    blue: '#0062FF',
  },
  styles: {
    // global: {
    //   'html': {
    //     fontWeight: 'bold'
    //   }
    // }
  }
});
