import {
  Box,
  Image,
  Container,
  VStack,
  HStack,
  Center,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import './App.css'
import KeyVisual from "./assets/key-visual-top.png"
import EllipseImage from "./assets/ellipse.png"
import TopImagePc from "./assets/top-pc.jpg"
import TopImageSp from "./assets/top-sp.jpg"
import BackDots from './assets/char-back-dots.svg'
import IntroBlueCircleImage from "./assets/intro-blue-circle.svg"
import IntroYellowCircleImage from "./assets/intro-yellow-circle.svg"
import IntroTopLineImage from "./assets/intro-top-line.svg"
import IntroBottomLineImage from "./assets/intro-bottom-line.svg"
import IntroWavesImage from "./assets/intro-waves.svg"

import { useRef } from "react"
import { useInView } from 'react-intersection-observer'
import { Parallax, ParallaxProvider } from "react-scroll-parallax"

export const Introduction = () => {
  const  rightCopyRef = useRef<HTMLDivElement>(null)
  const  topImageRef = useRef<HTMLImageElement>(null)
  const  keyVisualRef = useRef<HTMLImageElement>(null)
  const isMobile = useBreakpointValue({ base: true, md: false })
  const responsiveTopImageUrl = useBreakpointValue({ base: TopImageSp, md: TopImagePc, xl: TopImagePc})
  const topCopyFontSize = useBreakpointValue({ base: "sm", md: "md", xl: "xl"})
  setTimeout(() => {
    rightCopyRef.current?.classList.add("fade-in-slide")
    rightCopyRef.current?.classList.remove("initial-hidden")
  } , 800)
  setTimeout(() => {
    topImageRef.current?.classList.add("fade-in-slide")
    topImageRef.current?.classList.remove("initial-hidden")
  } , 1400)
  setTimeout(() => {
    keyVisualRef.current?.classList.add("fade-in-slide")
  } , 2300)

  const ParallaxObjects = () => {
    return(
      <ParallaxProvider>
        <Center py="7rem">
          <Box position="relative" height="276px" mt="2rem" w="400px">
            <Parallax speed={5}>
              <Image src={IntroYellowCircleImage} position="absolute" top="0" right="50px"/>
            </Parallax>
            <Parallax speed={-7}>
              <Image src={IntroBlueCircleImage} position="absolute" top="80px" right="180px"/>
            </Parallax>
            <Parallax speed={0}>
              <Image src={IntroWavesImage} position="absolute" top="20px" right="0" w="100%"/>
            </Parallax>
            <Parallax speed={-7}>
              <Image src={IntroTopLineImage} position="absolute" top="-30px" right="50px"/>
            </Parallax>
            <Parallax speed={10}>
              <Image src={IntroBottomLineImage} position="absolute" top="120px" right="300px"/>
            </Parallax>
            <Parallax speed={8}>
              <Image src={BackDots} position="absolute" top="150px" right="30px" />
            </Parallax>
          </Box>
        </Center>
      </ParallaxProvider>

    )
  }


  const IntroTexts = () => {
    const containerRef = useRef<HTMLDivElement>(null)
    const  yokohamaRef = useRef<HTMLParagraphElement>(null)
    const  shiratoriRef = useRef<HTMLParagraphElement>(null)
    const  sakuradaRef = useRef<HTMLParagraphElement>(null)
    const  friendshipRef = useRef<HTMLParagraphElement>(null)
    const { ref, inView } = useInView(
      { triggerOnce: false,
        threshold: 0.8,
      }
    )
    if(inView) {
      containerRef.current?.classList.add("fade-in")
      setTimeout(() => {
        yokohamaRef.current?.classList.add("fade-in")
      } , 500)
      setTimeout(() => {
        shiratoriRef.current?.classList.add("fade-in")
      } , 800)
      setTimeout(() => {
        sakuradaRef.current?.classList.add("fade-in")
      } , 1100)
      setTimeout(() => {
        friendshipRef.current?.classList.add("fade-in")
      } , 1400)
    }else {
      containerRef.current?.classList.remove("fade-in")
    }


    return (
      <Container maxW={["sm", "xl"]} textAlign="center" fontSize={isMobile ? "xs" : "md" } fontWeight="bold" ref={containerRef} opacity="0" pb="5rem">
        <Box ref={ref}>
          <VStack spacing="0.8rem" mb="2rem" >
            <Text>
              高校時代に同じ写真部で活動していた、
            </Text>
            <Box color="blue" >
              <HStack spacing="0.5">
                <Text ref={yokohamaRef} opacity="0">「横浜 成海」</Text>
                <Text ref={shiratoriRef} opacity="0">「白鳥 乃以」</Text>
                <Text ref={sakuradaRef} opacity="0">「櫻田 聖二郎」</Text>
              </HStack>
            </Box>
          </VStack>
          <VStack spacing="0.8rem">
            <Text>26歳になった彼らは仕事に忙殺される日々。</Text>
            <Text>そんな中、白鳥の誘いで久しぶりに再会した3人は</Text>
            <Text>大阪のレトロ建築巡りをすることに……。 </Text>
            <Text>あの頃はわからなかったお互いのこと、抱いていた想い。</Text>
            <Text>――3人の
              <Text as="strong" color="blue" ref={friendshipRef} opacity="0">「未完成な友情」</Text>
              が、今、動き出す。
            </Text>
          </VStack>
        </Box>
      </Container>

    )
  }

  return(
    <Box bgImage={EllipseImage} bgRepeat="no-repeat" bgSize="cover" bgPosition={isMobile ? "0px -291px" : "0px -1282px"} mb="3rem" id="introduction">
      <Box
      bgImage={responsiveTopImageUrl}
      bgRepeat="no-repeat"
      backgroundPosition={isMobile ? "50% 50%" : "50% 0%"}
      bgSize={isMobile ? "cover" : "cover"}
      className="blur-animation"
      pb="15rem">
        <Box
          h={isMobile ? "50vh" : "60vh"}
          >
            <Box
            sx={{ 'writingMode': 'vertical-rl'}}
            fontSize={topCopyFontSize}
            letterSpacing="0.5rem"
            fontWeight="bold"
            ref={rightCopyRef}
            className="initial-hidden"
            m="auto"
            pt={isMobile ? "3vh" : "4.5vh"}
            left="0"
            right="0">
              <Text ml="0.5rem">人生は終わるまで</Text>
              <Text ml="0.5rem">未完成の</Text>
              <Text ml="0.5rem">作品のような</Text>
              <Text>ものだから</Text>
            </Box>
        </Box>
        <Center borderRadius='50%'
          bgColor='yellow'
          width='35vw'
          height="35vw"
          maxW='200px'
          maxH='200px'
          margin='0 auto'
          position='absolute'
          transform='auto'
          rotate='-15.21'
          top='55vh'
          left='-10px'
          >
          <VStack spacing={0} fontFamily="Sawarabi Mincho" >
            <Text fontSize="sm">制作クラファン</Text>
            <HStack>
              <Text fontSize="lg">3/3</Text><Text fontSize="2xs">(Fri.)</Text>
            </HStack>
            <Text fontSize="sm">19:00~ Start</Text>
          </VStack>
        </Center>
      </Box>
      <Container>
        <Center position="relative">
          <Image src={KeyVisual} w="70%" ref={keyVisualRef} opacity={0} position="absolute" top="-150px"/>
        </Center>
      </Container>
      <ParallaxObjects />
      <IntroTexts />
    </Box>
  )
}