import {
  ChakraProvider,
  Box,
  Image,
  Container,
  Center,
  Spacer,
  Text,
  Link,
  Flex,
  Divider,
} from "@chakra-ui/react"
import { useInView } from 'react-intersection-observer'
import { useRef } from "react"
import { Introduction } from "./Introduction"
import { Characters } from "./Characters"
import { OnAir } from "./OnAir"
import { CrowdFunding } from "./CrowdFunding"
import { CastAndStaff } from "./CastAndStaff"
import { Sponsor } from "./Sponsor"
import { theme } from "./theme"
import { MenuDrawer } from "./MenuDrawer"
import KeyVisual from "./assets/key-visual.png"
import TwitterImage from "./assets/twitter.svg"
import { Timeline } from 'react-twitter-widgets';

export const App = () => (
  <ChakraProvider theme={theme}>
    <Box pos='relative' overflow='hidden' >
      <MenuDrawer />
      <Introduction />
      <Characters />
      <OnAir />
      <CrowdFunding />
      <CastAndStaff />
      <TwitterWidget />
      <FooterKeyVisual />
      <Footer />
    </Box>
  </ChakraProvider>
)



const TwitterWidget = () => {
  const  slideRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
    }
  )
  if(inView) {
    slideRef.current?.classList.add("fade-in-slide")
  }
  return (
    <Box my="4rem" ref={ref}>
      <Container maxW={["sm", "xl"]} ref={slideRef}>
        <Timeline
          dataSource={{
            sourceType: 'profile',
            screenName: 'mikacyaofficial'
          }}
          options={{ lang: 'ja', height: 400 }}/>
        {/* <a href="https://twitter.com/mikacyaofficial?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-size="large" data-lang="ja" data-show-count="false">Follow @mikacyaofficial</a><script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"></script> */}
      </Container>
    </Box>
  )
}

const FooterKeyVisual = () => {
  const  slideRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
    }
  )
  if(inView) {
    slideRef.current?.classList.add("fade-in-slide")
  }
  return (
    <Container ref={ref}>
      <Center my="6rem" ref={slideRef}>
        <Image src={KeyVisual} w="70%"/>
      </Center>
      <Sponsor />
    </Container>
  )
}

const Footer = () => {
  const  slideRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
    }
  )
  if(inView) {
    slideRef.current?.classList.add("fade-in-slide")
  }
  return (
    <Container my="5rem" ref={ref}>
      <Divider borderColor="black" my="1rem"/>
      <Flex m="1.5rem" ref={slideRef}>
        <Text fontSize="xs">2023 未完成Project 制作委員会</Text>
        <Spacer/>
        <Link href="https://twitter.com/mikacyaofficial" isExternal>
          <Image src={TwitterImage} />
        </Link>
      </Flex>
    </Container>
  )
}

