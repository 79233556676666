import {  Box, Button, Center, Container, Heading, Link, Text} from "@chakra-ui/react"
import { useRef } from "react"
import { useInView } from 'react-intersection-observer'

export const CrowdFunding = () => {
  const sectionTitleRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    { 
      triggerOnce: true,
    }
  )
  if(inView) {
    sectionTitleRef.current?.classList.add("slide-from-left")
    containerRef.current?.classList.add("fade-in-slide")
    sectionTitleRef.current?.classList.remove("initial-hidden")
  }
  return(
    <Box mb="2rem" id="crowdfunding" ref={ref}>
      <Container maxW={["sm", "xl"]} mb="3rem" ref={ref}>
        <Heading as="h1" display="inline-block" bgColor="yellow" padding="1rem 3rem" fontWeight="medium" ref={sectionTitleRef} className="initial-hidden">
          Crowdfunding
        </Heading>
      </Container>
      <Box bgColor="black" color="white" fontSize="xs" fontWeight="thin" py="3rem" px="2rem" >
        <Container className="initial-hidden" ref={containerRef}>
          <Text>当作品は2023年3月3日(金) 19:00~ より開始予定のクラウドファンディングにて制作資金を募ります。目標額を達成した際のみ作品の制作がスタートいたします。<br/><br/>
          最新情報は作品公式ツイッターにて、またクラウドファンディングプラットフォームは<Link color="yellow" href="https://greenfunding.jp" isExternal>GREEN FUNDING</Link>にてスタートいたします。会員登録をお済ませの上、スタートをお待ちください。<br/><br/>
          クラウドファンディングのリターンには、限定のグッズやイベントのご招待チケットなどをご用意する予定です。<br/>
          みなさまの暖かいご支援、何卒宜しくお願いいたします。<br/><br/>
          未完成Project 製作委員会　一同</Text>
        </Container>
        <Center mt="2rem">
          <Link href="https://greenfunding.jp/lab/projects/6998" isExternal>
            <Button p="0px" backgroundColor="white" borderRadius="50px"  fontWeight="thin">
              <Center color="blue" px="20px" h="40px" position="relative">
                <Text fontFamily="Montserrat" textAlign="center">クラファンページはこちら</Text>
              </Center>
            </Button>
          </Link>
        </Center>
      </Box>
    </Box>
  )
}