import { Box, Button, Center, Container, Flex, Heading, HStack, IconButton, Image, Img, Spacer, Text, VStack } from "@chakra-ui/react"
import { useSound} from 'use-sound'
import '@fontsource/montserrat'
import '@fontsource/sawarabi-gothic'
import parse from "html-react-parser"
import { AiFillPauseCircle, AiFillPlayCircle } from "react-icons/ai"
import CircleNote from "./CircleNote"
import BackDots from './assets/char-back-dots.svg'
import { useInView } from 'react-intersection-observer'
import { useRef, useState } from "react"

interface CharacterArgs{
  lastName: string,
  firstName: string,
  nameAlpha: string,
  cv: string,
  copy: string,
  description: string
  image: string,
  characterImageX: number,
  voiceUrls: string[],
  characterAlign: 'left' | 'right'
}

export const Character = (props: CharacterArgs ) => {
  const charImageRef = useRef<HTMLImageElement>(null)
  const blackBackRef = useRef<HTMLDivElement>(null)
  const profileRef = useRef<HTMLDivElement>(null)
  const copyRef = useRef<HTMLDivElement>(null)
  const audioPlayRef = useRef<HTMLDivElement>(null)
  const { ref, inView } = useInView(
    {
      triggerOnce: true,
      threshold: 0.8,
    }
  )
  if(inView) {
    charImageRef.current?.classList.add('fade-in-slide')
    setTimeout(() => {
      blackBackRef.current?.classList.add("fade-in-slide")
    } , 500)
    setTimeout(() => {
      profileRef.current?.classList.remove("initial-hidden")
      profileRef.current?.classList.add("fade-in-slide")
    } , 750)
    setTimeout(() => {
      copyRef.current?.classList.remove("initial-hidden")
      copyRef.current?.classList.add("blur-animation")
    } , 1000)
    setTimeout(() => {
      audioPlayRef.current?.classList.add("fade-in-slide")
    } , 1250)
  }
  const [play0, action0] = useSound(props.voiceUrls[0])
  const [play1, action1] = useSound(props.voiceUrls[1])
  const [ playing0, setPlaying0 ] = useState(false)
  const [ playing1, setPlaying1 ] = useState(false)
  const isLeftAlign = props.characterAlign == 'left'

  const onClickPlayButton = (voiceIndex: number) => {
    if(voiceIndex == 0) {
      playing0 ? action0.pause() : play0()
      setPlaying0(!playing0)
    }else if(voiceIndex == 1){
      playing1 ? action1.pause() : play1()
      setPlaying1(!playing1)
    }
  }

  return (
  <Box position="relative" h="700px">
    <Box bgColor="black" color="white" textAlign="center" position="absolute" top="200px" w="100%" ref={blackBackRef} opacity="0">
      <Container pt="2rem" pl="2rem" pb="5rem" ref={ref}>
        <HStack>
          {
            isLeftAlign ? '' : (<Spacer/>)
          }
          <Box>
            <Text fontSize="2xs" fontFamily="Montserrat" pb="1rem">{ props.nameAlpha }</Text>
            <Box>
              <Box lineHeight="3.5rem">
                <Text fontSize="5xl" fontFamily="Sawarabi Gothic">{ props.lastName }</Text>
                <Text fontSize="5xl" fontFamily="Sawarabi Gothic">{ props.firstName }</Text>
              </Box>
              <Text fontSize="xs" fontFamily="Montserrat" >{ props.cv }</Text>
            </Box>
          </Box>
          {
            isLeftAlign ? (<Spacer/>) : ''
          }
        </HStack>
      </Container>
    </Box>
    <Image src={props.image} position="absolute" top="30px" 
          left={ isLeftAlign ? props.characterImageX : "auto" }
          right={ isLeftAlign? "auto" : props.characterImageX }
          opacity="0"
          ref={charImageRef}/>
    <Box ref={copyRef} className="initial-hidden">
      {/* Black background blur*/}
      <Text
        sx={{ 'writing-mode': 'vertical-rl'}}
        letterSpacing="0.3rem"
        textShadow="1px 1px 5px black, -1px -1px 5px black"
        color="transparent"
        height="500px"
        position="absolute"
        top="30px"
        ref={copyRef}
        left={ isLeftAlign ? "auto" : "41px" }
        right={ isLeftAlign? "41px" : "auto" }
        >{ parse(props.copy) }
      </Text>
      {/* White background blur*/}
      <Text
        sx={{ 'writing-mode': 'vertical-rl'}}
        letterSpacing="0.3rem"
        textShadow="1px 1px 5px white, -1px -1px 5px white"
        color="transparent"
        height="500px"
        position="absolute"
        top="30px"
        
        left={ isLeftAlign ? "auto" : "41px" }
        right={ isLeftAlign? "41px" : "auto" }
        >{ parse(props.copy) }
      </Text>
      <Text
        sx={{ 'writing-mode': 'vertical-rl'}}
        letterSpacing="0.3rem"
        color="white"
        height="500px"
        position="absolute"
        top="30px"
        ref={copyRef}
        left={ isLeftAlign ? "auto" : "41px" }
        right={ isLeftAlign? "41px" : "auto" }
        >{ parse(props.copy) }
      </Text>
    </Box>

    <Box ref={profileRef} className="initial-hidden">
      <CircleNote content={props.description} isLeftSide={isLeftAlign}/>
    </Box>
    
    <Image src={BackDots}
      w="20px"
      position="absolute"
      top="520px"
      left={ isLeftAlign ? "auto" : "70px" }
      right={ isLeftAlign? "70px" : "auto" } />
    <VStack
      spacing="1.5rem"
      position="absolute"
      top="520px"
      left={ isLeftAlign ? "auto" : "0px" }
      right={ isLeftAlign? "0px" : "auto" }
      ref={audioPlayRef}
      opacity="0">
      <Button variant="ghost" p="0px" colorScheme="whiteAlpha" fontWeight="thin" onClick={() => { onClickPlayButton(0) }}>
        <Center bgColor="blue" color="white" w="160px" h="40px" borderRadius="30px" position="relative">
          <Text fontFamily="Montserrat" textAlign="center"> Voice 1</Text>
          <Box position="absolute" right="20px">
            {
              playing0 ?  <AiFillPauseCircle size="1.2rem"/> :  <AiFillPlayCircle size="1.2rem"/>
            }
          </Box>
        </Center>
      </Button>
      <Button variant="ghost" p="0px" colorScheme="whiteAlpha" fontWeight="thin" onClick={() => { onClickPlayButton(1) }}>
        <Center bgColor="blue" color="white" w="160px" h="40px" borderRadius="30px" position="relative">
          <Text fontFamily="Montserrat" textAlign="center"> Voice 2</Text>
          <Box position="absolute" right="20px">
            {
              playing1 ?  <AiFillPauseCircle size="1.2rem"/> :  <AiFillPlayCircle size="1.2rem"/>
            }
          </Box>
        </Center>
      </Button>
    </VStack>
  </Box>
  )
}
